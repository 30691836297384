<template>
  <div>
    <Modal :modalConfig="{ ...modalConfig }" @onClose="onClose" @onOk="onOk">
      <div class="modal-container">
        <div class="transfer-container">
          <el-card shadow="never" class="card-container">
            <div slot="header" class="clearfix">
              <span>所有{{ transferConfig.title }}</span>
            </div>
            <div v-loading="loading">
              <div class="queryBtn">
                <el-input placeholder="终端名称" v-model="params.userName" clearable size="mini"> </el-input>
                <el-input placeholder="所属组织" v-model="params.orgName" clearable size="mini"> </el-input>
                <el-button icon="el-icon-search" type="danger" circle @click="queryBtn"></el-button>
              </div>
              <vxe-table
                border
                show-overflow
                :data="treeData"
                @checkbox-all="selectAllEvent"
                @checkbox-change="selectChangeEvent"
                :checkbox-config="{checkRowKeys: defaultSelecteRows}"
                rowId="userCode"
              >
                <vxe-table-column type="checkbox" width="40"></vxe-table-column>
                <vxe-table-column field="userCode" :title="transferConfig.title+'编码'"></vxe-table-column>
                <vxe-table-column field="userName" :title="transferConfig.title+'名称'"></vxe-table-column>
                <vxe-table-column field="orgName" title="所属组织"></vxe-table-column>
              </vxe-table>
              <div class="pagination">
                <el-pagination small background layout="total,prev, pager, next" @current-change="handleCurrentChange" :current-page="pagination.pageNum" :page-size="pagination.pageSize" :total="pagination.total"></el-pagination>
              </div>
            </div>
          </el-card>
        </div>
        <div class="button-container">
          <div class="buttons">
            <el-button @click="addCurrent" type="mini" :disabled="typeCode==='view'"
              >添加{{ transferConfig.title }}<i class="el-icon-arrow-right"></i
            ></el-button>
            <el-button @click="removeTableData" type="mini" icon="el-icon-arrow-left" :disabled="typeCode==='view'">移除</el-button>
          </div>
        </div>
        <div class="transfer-container">
          <el-card shadow="never" class="card-container">
            <div slot="header" class="clearfix">
              <span>已选{{ transferConfig.title }}</span>
            </div>
            <div>
              <vxe-table
                border
                show-overflow
                :data="selectData"
                @checkbox-all="selectAllEventNew"
                @checkbox-change="selectChangeEventNew"
              >
                <vxe-table-column type="checkbox" width="40"></vxe-table-column>
                <vxe-table-column field="userCode" :title="transferConfig.title+'编码'"></vxe-table-column>
                <vxe-table-column field="userName" :title="transferConfig.title+'名称'"></vxe-table-column>
                <vxe-table-column field="orgName" title="所属组织"></vxe-table-column>
              </vxe-table>
            </div>
          </el-card>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { isArray } from 'highcharts';
import Modal from '@/found/components/modal/components/full.vue';
import request from '@/found/utils/request';

export default {
  components: {
    Modal,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    customerCode: String,
    typeCode: String,
    actDetailCode: String,
  },
  data() {
    return {
      modalConfig: {
        visible: false,
        title: '',
        showFooter: true,
        width: '85%',
      },
      transferConfig: {
        title: '',
        api: '',
      },
      treeData: [],
      selectData: [],
      selectTableData: [],
      selectTableDataNew: [],
      defaultSelecteRows: [],
      loading: false,
      pagination: {
        total: 0,
        pageSize: 20,
        pageNum: 1,
      },
      params: {
        userName: '',
        orgName: '',
      },
    };
  },
  watch: {
    modalConfig(val) {
      if (val.visible) {
        this.treeData = [];
        this.selectTableDataNew = [];
        this.getTreeList();
        if (this.typeCode !== 'add' && this.actDetailCode) {
          this.getSeleteList();
        }
      }
    },
    tableData(val) {
      this.selectData = isArray(val) ? val : [];
    },
  },
  created() {},
  mounted() {},
  methods: {
    onClose() {
      this.modalConfig.visible = false;
    },
    onOk() {
      this.modalConfig.visible = false;
      this.$emit('onOk', this.selectData);
    },
    selectAllEventNew({ checked, records }) {
      this.selectTableDataNew = records;
    },
    selectChangeEventNew({ checked, records }) {
      this.selectTableDataNew = records;
    },
    /** @desc 分页page * */
    handleCurrentChange(val) {
      this.pagination.pageNum = val;
      this.getTreeList();
    },
    queryBtn() {
      this.pagination.pageNum = 1;
      this.getTreeList();
    },
    getSeleteList() {
      const data = {
        actDetailCode: this.actDetailCode,
        pageNum: 1,
        pageSize: 20,
      };
      this.loading = true;
      request.post(this.transferConfig.viewApi, data).then((res) => {
        this.loading = false;
        if (res.success) {
          this.selectData = res.result;
          const defaultSelecteRows = res.result;
          this.defaultSelecteRows = defaultSelecteRows.map((v) => v.userCode);
        }
      }).catch((e) => {
        this.loading = false;
      });
    },
    // 获取数据
    getTreeList() {
      const data = {
        customerCode: this.customerCode,
        pageNum: this.pagination.pageNum,
        pageSize: this.pagination.pageSize,
        ...this.params,
      };
      this.loading = true;
      request.post(this.transferConfig.api, data).then((res) => {
        this.loading = false;
        if (res.success) {
          this.treeData = res.result.data;
          this.pagination.total = res.result.count;
        }
      }).catch((e) => {
        this.loading = false;
      });
    },
    selectAllEvent({ checked, records }) {
      this.selectTableData = records;
    },
    selectChangeEvent({ checked, records }) {
      this.selectTableData = records;
    },
    // 添加当前组织
    addCurrent() {
      if (this.selectTableData && this.selectTableData.length > 5) {
        this.$message.error('最多选择5个市场支持人员，请确认选择');
      } else {
        const selectTableKeys = this.selectTableData.map((item) => item.userCode);
        this.selectData = this.selectData.filter((item) => !selectTableKeys.includes(item.userCode));
        this.selectData.push(...this.selectTableData);
      }
    },
    // 移除
    removeTableData() {
      const selectTableKeys = this.selectTableDataNew.map((item) => item.userCode);
      this.selectData = this.selectData.filter((item) => !selectTableKeys.includes(item.userCode));
    },
  },
};
</script>

<style lang="less" scoped>
.modal-container {
  display: flex;
  height: 100%;
  .transfer-container {
    flex: 1 0 0;
    .card-container {
      height: 100%;
      overflow-y: scroll;
    }
  }
  .button-container {
    width: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .buttons {
      height: 160px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
  }
}
.queryBtn{
  display: flex;
  margin-bottom: 10px;
}
.pagination{
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active{
  color: white !important;
}
</style>
